import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, LinkBox, Strong, Span, Icon, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaChartArea, FaRegFileCode, FaSchool, FaClinicMedical, FaShoppingBasket } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Top AI Tools
			</title>
			<meta name={"description"} content={"AI tool collections for professionals"} />
			<meta property={"og:title"} content={"Top AI Tools for marketers & content creators"} />
			<meta property={"og:description"} content={"AI tool collections for professionals"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15:24:22.968Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15:24:22.968Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon270.svg?v=2021-09-21T16:18:07.731Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 36px 0" quarkly-title="Header" overflow-y="visible">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="30%"
				sm-width="50%"
				display="flex"
				align-items="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
			>
				<Image
					height="auto"
					src="https://uploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15:24:22.968Z"
					width="100%"
					max-width="171px"
					srcSet="https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="70%"
				sm-width="50%"
				align-items="center"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
			>
				<Components.QuarklycommunityKitMobileSidePanel md-z-index="9">
					<Override
						slot="Content"
						align-items="center"
						padding="16px 0px 16px 16px"
						md-background="--color-light"
						md-z-index="9"
						md-justify-content="flex-start"
						background="rgba(255, 255, 255, 0)"
						justify-content="center"
					/>
					<Override slot="Children" justify-content="center" display="flex" align-items="center" />
					<Override slot="Button Text" md-display="none" />
					<Override slot="Button Icon" md-color="--white" md-font="36px sans-serif" />
					<Override slot="Cross" md-color="--dark" md-font="36px sans-serif" />
					<Override slot="Wrapper" md-z-index="9" />
					<Box
						display="flex"
						align-items="center"
						justify-content="flex-end"
						md-flex-direction="column"
						md-margin="40px 0px 13px 0px"
						md-font="--headline4"
					>
						<Link
							href="#"
							font="normal 600 18px/1.5 --fontFamily-googleInter"
							text-decoration-line="initial"
							color="--white"
							margin="0px 20px 0px 20px"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							md-color="--indigo"
						>
							Home
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 80px 0" quarkly-title="HeroBlock" md-padding="36px 0 60px 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 90px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="60%"
				padding="0px 180px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 8px 0px"
					color="--grey"
					lg-text-align="left"
					font="--base"
					text-transform="uppercase"
					lg-margin="0px 0px 8px 0px"
					sm-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
				>
					Revolutionize Your Strategy
				</Text>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Top AI Tools for Marketers & Content Creators
				</Text>
			</Box>
			<Box
				empty-border-style="solid"
				width="40%"
				display="flex"
				justify-content="center"
				empty-border-width="1px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				align-items="center"
				md-width="100%"
				md-order="-1"
				empty-min-width="64px"
				text-align="center"
			>
				<Image
					lg-max-height="400px"
					width="100%"
					max-height="450px"
					md-margin="0px 0px 30px 0px"
					src="https://uploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/0_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_esrgan-v1-x2plus%20%281%29.png?v=2023-10-04T14:00:09.224Z"
					lg-position="relative"
					lg-left="-5px"
					max-width="100%"
					srcSet="https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/0_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_esrgan-v1-x2plus%20%281%29.png?v=2023-10-04T14%3A00%3A09.224Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/0_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_esrgan-v1-x2plus%20%281%29.png?v=2023-10-04T14%3A00%3A09.224Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/0_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_esrgan-v1-x2plus%20%281%29.png?v=2023-10-04T14%3A00%3A09.224Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/0_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_esrgan-v1-x2plus%20%281%29.png?v=2023-10-04T14%3A00%3A09.224Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/0_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_esrgan-v1-x2plus%20%281%29.png?v=2023-10-04T14%3A00%3A09.224Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/0_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_esrgan-v1-x2plus%20%281%29.png?v=2023-10-04T14%3A00%3A09.224Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/0_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_esrgan-v1-x2plus%20%281%29.png?v=2023-10-04T14%3A00%3A09.224Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="24px 0 24px 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				flex-direction="column"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					position="static"
					lg-left="0px"
					md-width="100%"
					border-radius="10px"
					lg-max-height="366px"
					src="https://uploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/705599__Design%20an%20illustration%20that%20captures%20the%20essence%20_xl-1024-v1-0.png?v=2023-10-04T14:05:03.170Z"
					left="-140px"
					max-width="100%"
					object-fit="cover"
					srcSet="https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/705599__Design%20an%20illustration%20that%20captures%20the%20essence%20_xl-1024-v1-0.png?v=2023-10-04T14%3A05%3A03.170Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/705599__Design%20an%20illustration%20that%20captures%20the%20essence%20_xl-1024-v1-0.png?v=2023-10-04T14%3A05%3A03.170Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/705599__Design%20an%20illustration%20that%20captures%20the%20essence%20_xl-1024-v1-0.png?v=2023-10-04T14%3A05%3A03.170Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/705599__Design%20an%20illustration%20that%20captures%20the%20essence%20_xl-1024-v1-0.png?v=2023-10-04T14%3A05%3A03.170Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/705599__Design%20an%20illustration%20that%20captures%20the%20essence%20_xl-1024-v1-0.png?v=2023-10-04T14%3A05%3A03.170Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/705599__Design%20an%20illustration%20that%20captures%20the%20essence%20_xl-1024-v1-0.png?v=2023-10-04T14%3A05%3A03.170Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/705599__Design%20an%20illustration%20that%20captures%20the%20essence%20_xl-1024-v1-0.png?v=2023-10-04T14%3A05%3A03.170Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-style="solid"
				width="50%"
				padding="16px 16px 16px 16px"
				display="flex"
				md-width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				flex-direction="column"
				align-items="flex-start"
			>
				<Text
					width="100%"
					lg-width="100%"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--indigo"
					sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Empowering Digital Pioneers
				</Text>
				<Text
					font="--base"
					opacity=".7"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 25px 0px"
					color="--dark"
				>
					Dive into our expertly curated collection of AI tools tailored for marketers and content creators, designed to enhance creativity and optimize strategies. Our handpicked selection, backed by unbiased reviews and continuous updates, ensures you stay at the forefront of the AI revolution. Navigate AI with ease through our comprehensive guides, and join a community that values genuine insights and data security. Ready to transform your content and marketing endeavours? Explore with us and redefine the future.
					<br />
					<br />
					<br />
					If you sign up for tools or services through links on this site, I may earn a commission at no extra cost to you. I only recommend products I trust. Thanks for your support!
				</Text>
			</Box>
		</Section>
		<Section lg-padding="60px 0 40px 0" padding="60px 0 100px 0" md-padding="30px 0 30px 0">
			<Text
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 40px 0px"
				margin="0px 0px 80px 0px"
				font="--headline2"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
				text-align="center"
			>
				AI Toolkit for Marketers and Creators
			</Text>
			<Text margin="0px 0px 0px 0px" text-align="center" padding="15px 0px 15px 0px" font="--lead">
				Shortcuts
			</Text>
			<Box min-width="100px" min-height="100px" text-align="center" margin="10px 0px 10px 0px">
				<Link href="#adcreativeai" color="#000000" padding="10px 10px 10px 10px">
					#GenerateAdCreatives
				</Link>
				<Link href="#getresponse" color="#000000" padding="10px 10px 10px 10px">
					#EmailMarketing
				</Link>
			</Box>
			<Box
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				padding="0px 0px 0px 0px"
				border-style="solid"
				display="flex"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
				width="100%"
				border-color="rgba(66, 82, 207, 0.1)"
				md-border-color="rgba(66, 82, 207, 0.1)"
			>
				<Box display="flex" lg-width="50%" md-width="100%" width="40%">
					<LinkBox display="flex" width="100%" href="https://griap.link/resources/hit/1558/CPhFjDWyX9" target="_blank">
						<Image
							margin="-1px 0px -1px -1px"
							src="https://griap.link/resources/view/1558/CPhFjDWyX9"
							object-fit="cover"
							width="100%"
							border-radius="10px"
							md-margin="0px 0px 0px 0px"
						/>
					</LinkBox>
				</Box>
				<Box
					padding="32px 10% 32px 62px"
					width="60%"
					justify-content="space-between"
					md-width="100%"
					md-padding="32px 30px 32px 0px"
					display="flex"
					flex-direction="column"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
					id="getresponse"
				>
					<LinkBox
						bottom="auto"
						lg-right="16px"
						lg-top="49px"
						position="absolute"
						right="32px"
						top="32px"
						left="auto"
						href="https://www.getresponse.com?a=CPhFjDWyX9"
					>
						<Image
							width="48px"
							height="48px"
							top="32px"
							left="auto"
							lg-height="24px"
							src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z"
							position="static"
							right="32px"
							bottom="auto"
							lg-width="24px"
						/>
					</LinkBox>
					<Text
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
						margin="0px 0px 8px 0px"
						font="--headline3"
					>
						Elevate Your Email Marketing Game
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Link
							href="https://www.getresponse.com?a=CPhFjDWyX9"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							GetResponse
						</Link>
						{" "}is a comprehensive email marketing platform that offers AI-driven campaign tools, high deliverability rates, and engagement features. It provides seamless integration with over 100+ tools and offers API access for custom solutions. From creating landing pages to automating marketing workflows,{" "}
						<Link
							href="https://www.getresponse.com?a=CPhFjDWyX9"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							GetResponse
						</Link>
						{" "}streamlines digital communication for businesses.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							AI-Driven Email Marketing:
						</Strong>
						{" "}Utilise AI-powered tools for generating emails and campaigns tailored to your audience.
						<br />
						High Deliverability: Achieve a 99% deliverability rate across 160+ countries.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Growth Metrics:
						</Strong>
						{" "}Experience an average list growth of 100% in the first 30 days of using{" "}
						<Link
							href="https://www.getresponse.com?a=CPhFjDWyX9"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							GetResponse
						</Link>
						.
						<br />
						Comprehensive Features: From AI email generators, autoresponders, and marketing automation to landing pages, signup forms, and popups.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Engagement Tools:
						</Strong>
						{" "}Convert website visitors with timely popups, web push notifications, and live chats.
						<br />
						Integration Capabilities: Sync data with over 100+ integrations, ensuring seamless workflows and enhanced customer experience.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Affordable Pricing:{" "}
						</Strong>
						Plans start from $15/month, with features like unlimited monthly emails, AI-powered email marketing, landing pages, and more.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Custom Solutions:
						</Strong>
						{" "}
						<Link href="https://www.getresponse.com?a=CPhFjDWyX9">
							GetResponse{" "}
						</Link>
						MAX plans offer tailored support, high-volume infrastructure, AI conversion tools, and multi-user support.
					</Text>
				</Box>
			</Box>
			<Box
				display="flex"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				md-border-width="0px 0px 2px 0px"
				margin="0px 0px 20px 0px"
				width="100%"
				border-style="solid"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				border-radius="10px"
				flex-wrap="wrap"
				min-height="284px"
				md-border-radius="0px"
			>
				<Box
					padding="32px 10% 32px 62px"
					width="70%"
					display="flex"
					position="relative"
					md-padding="32px 30px 32px 0px"
					flex-direction="column"
					justify-content="space-between"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
					md-width="100%"
					id="adcreativeai"
				>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline3"
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					>
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Unlock the Future of Advertising: The Power of{" "}
								<Link href="https://free-trial.adcreative.ai/fmeysxhzbod8" color="--indigo">
									AdCreative AI
								</Link>
							</Span>
						</Span>
					</Text>
					<LinkBox
						lg-right="16px"
						lg-top="49px"
						position="absolute"
						right="32px"
						top="32px"
						left="auto"
						bottom="auto"
						href="https://free-trial.adcreative.ai/fmeysxhzbod8"
					>
						<Image
							src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z"
							left="auto"
							lg-height="24px"
							top="32px"
							bottom="auto"
							lg-width="24px"
							width="48px"
							height="48px"
							position="static"
							right="32px"
						/>
					</LinkBox>
					<Text
						margin="0px 0px 0px 0px"
						font="--lead"
						color="--red"
						text-align="center"
						padding="10px 0px 10px 0px"
					>
						Signup Now and Get 10 Free Credits
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Link
							href="https://free-trial.adcreative.ai/krnq4phxly2m-ugw3ws"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							AdCreative.ai
						</Link>
						{" "}offers a variety of ad creative types generated using artificial intelligence. Here are the types of ad creatives it can create:
						<br />
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<br />
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Texts & Headlines:{" "}
							</Strong>
						</Span>
						Generate sales-focused texts and headlines tailored to the platform you advertise on. The AI acts as your copy-writer, allowing you to focus on other aspects of your business.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Social Creatives:{" "}
						</Strong>
						Create engagement-focused social media post creatives in minutes. It supports various platforms including Facebook, Instagram, LinkedIn, Pinterest, and Twitter.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Complete Ad Packages:{" "}
						</Strong>
						The AI understands your product/service, creates a strategy, and delivers ad creatives, texts, and audiences - providing everything needed for an effective ad campaign.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Video Ads:{" "}
						</Strong>
						Generate conversion-focused videos for ads.{" "}
						<Link
							href="https://free-trial.adcreative.ai/fmeysxhzbod8"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							AdCreative.ai
						</Link>
						{" "}claims to be the only AI that can create ready-to-use videos for high ROI.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							User Input Requirements:{" "}
						</Strong>
						Target Audience: Users need to specify their target audience.
						<br />
						Ad Platform: Users should mention the platform where the ad will be displayed. The AI will optimize the tone and length of the content accordingly.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<br />
							Additional Features:
							<br />
							<br />
						</Strong>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							{" "}- Creative Insights:
						</Strong>
						{" "}Users can see which creatives perform the best in their ad accounts and get inspired.
						<br />
						<br />
						{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							- Integration:
						</Strong>
						{" "}
						<Link href="https://free-trial.adcreative.ai/fmeysxhzbod8">
							AdCreative.ai
						</Link>
						{" "}can be integrated with Google, Facebook, ADYOUNEED, and Zapier.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							{" "}- Collaboration:
						</Strong>
						{" "}Allows multiple users to generate creatives simultaneously under one main account.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							How It Works:
							<br />
						</Strong>
						{" "}- Users simply inform the AI about their target audience and the platform where the ads will be displayed.
						<br />
						{" "}- The AI selects the best tone and length for the content while focusing on the audience’s pain points.{" "}
						<br />
						{" "}- It generates conversion-focused ad creatives quickly, allowing users to test more creatives while reducing the time spent on the design process.
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--lead"
						color="--red"
						text-align="center"
						padding="10px 0px 10px 0px"
					>
						Signup Now and Get 10 Free Credits
						<br />
						{"\n\n"}
					</Text>
				</Box>
				<Box
					md-order="-1"
					width="30%"
					display="flex"
					lg-width="50%"
					md-width="100%"
					height="fit-content"
					text-align="center"
					justify-content="space-around"
				>
					<LinkBox href="https://free-trial.adcreative.ai/krnq4phxly2m-ugw3ws" align-content="space-around" width="100%">
						<Image
							object-fit="cover"
							width="100%"
							border-radius="10px"
							md-margin="0px 0px 0px 0px"
							margin="-1px -1px -1px 0px"
							src="https://uploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/IMG_3759.PNG?v=2023-10-11T13:49:20.102Z"
							text-align="center"
							srcSet="https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/IMG_3759.PNG?v=2023-10-11T13%3A49%3A20.102Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/IMG_3759.PNG?v=2023-10-11T13%3A49%3A20.102Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/IMG_3759.PNG?v=2023-10-11T13%3A49%3A20.102Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/IMG_3759.PNG?v=2023-10-11T13%3A49%3A20.102Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/IMG_3759.PNG?v=2023-10-11T13%3A49%3A20.102Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/IMG_3759.PNG?v=2023-10-11T13%3A49%3A20.102Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/IMG_3759.PNG?v=2023-10-11T13%3A49%3A20.102Z&quality=85&w=3200 3200w"
							sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</LinkBox>
				</Box>
			</Box>
			<Box
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				padding="0px 0px 0px 0px"
				border-style="solid"
				display="flex"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
				width="100%"
				border-color="rgba(66, 82, 207, 0.1)"
				md-border-color="rgba(66, 82, 207, 0.1)"
			>
				<Box display="flex" lg-width="50%" md-width="100%" width="30%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://uploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/511893_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_xl-1024-v1-0.png?v=2023-10-04T14:45:43.821Z"
						object-fit="cover"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						srcSet="https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/511893_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_xl-1024-v1-0.png?v=2023-10-04T14%3A45%3A43.821Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/511893_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_xl-1024-v1-0.png?v=2023-10-04T14%3A45%3A43.821Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/511893_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_xl-1024-v1-0.png?v=2023-10-04T14%3A45%3A43.821Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/511893_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_xl-1024-v1-0.png?v=2023-10-04T14%3A45%3A43.821Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/511893_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_xl-1024-v1-0.png?v=2023-10-04T14%3A45%3A43.821Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/511893_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_xl-1024-v1-0.png?v=2023-10-04T14%3A45%3A43.821Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/511893_Design%20a%20captivating%20hero%20image%20for%20a%20website%20dedi_xl-1024-v1-0.png?v=2023-10-04T14%3A45%3A43.821Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					padding="32px 17% 32px 62px"
					width="70%"
					justify-content="space-between"
					md-width="100%"
					md-padding="32px 30px 32px 0px"
					display="flex"
					flex-direction="column"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
				>
					<LinkBox
						bottom="auto"
						lg-right="16px"
						lg-top="49px"
						position="absolute"
						right="32px"
						top="32px"
						left="auto"
						href="https://chat.openai.com/"
					>
						<Image
							width="48px"
							height="48px"
							top="32px"
							left="auto"
							lg-height="24px"
							src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z"
							position="static"
							right="32px"
							bottom="auto"
							lg-width="24px"
						/>
					</LinkBox>
					<Text
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
						margin="0px 0px 8px 0px"
						font="--headline3"
					>
						Benefits of ChatGPT & GPT Plus for Marketers and Content Creators:
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							AI-Powered Content:{" "}
						</Strong>
						Efficiently generate and refine content using advanced artificial intelligence.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Effective Adverts:{" "}
						</Strong>
						Craft adverts that resonate and convert.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Boost Social Engagement:{" "}
						</Strong>
						Create captivating social media posts to enhance reach.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Market Insights:
						</Strong>
						{" "}Delve into market trends and audience preferences.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Quality Writing:
						</Strong>
						{" "}Produce standout articles, scripts, and narratives.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Tailored Content:
						</Strong>
						{" "}Adapt content to cater to diverse audiences.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Efficiency:
						</Strong>
						{" "}Streamline content creation and marketing tasks.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Role-Playing for Optimal Results:{" "}
						</Strong>
						To harness the full potential of these tools, users often engage in role-playing with GPT. This interactive approach, akin to guiding a conversation, helps in refining outputs and achieving the desired results.
						<br />
						<Strong>
							Beta Features:
						</Strong>
						{" "}ChatGPT and GPT Plus are continuously evolving. Current beta features include integrated plugins, Bing search functionality, and the ability to communicate verbally with the AI via the phone app.
					</Text>
				</Box>
			</Box>
			<Box
				display="flex"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				md-border-width="0px 0px 2px 0px"
				margin="0px 0px 20px 0px"
				width="100%"
				border-style="solid"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				border-radius="10px"
				flex-wrap="wrap"
				min-height="284px"
				md-border-radius="0px"
			>
				<Box
					padding="32px 17% 32px 62px"
					width="70%"
					display="flex"
					position="relative"
					md-padding="32px 30px 32px 0px"
					flex-direction="column"
					justify-content="space-between"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
					md-width="100%"
				>
					<LinkBox
						lg-right="16px"
						lg-top="49px"
						position="absolute"
						right="32px"
						top="32px"
						left="auto"
						bottom="auto"
						href="https://stability.ai/"
					>
						<Image
							src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z"
							left="auto"
							lg-height="24px"
							top="32px"
							bottom="auto"
							lg-width="24px"
							width="48px"
							height="48px"
							position="static"
							right="32px"
						/>
					</LinkBox>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline3"
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					>
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Benefits of Stable Diffusion for Marketers and Content Creators
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Realistic Visuals at Your Fingertips:{" "}
						</Strong>
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							The striking image of the man next this paragraph is a testament to Stable Diffusion's prowess, showcasing its ability to craft lifelike and detailed visuals with ease.
							<br />
							<Strong>
								Descriptive Image Generation:
							</Strong>
						</Span>
						{" "}With Stable Diffusion XL, users can create detailed images using shorter prompts, making the process more intuitive and efficient.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Enhanced Image Composition:
						</Strong>
						{" "}The model offers advanced image composition capabilities, ensuring that the visuals produced are not only stunning but also realistic.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Face Generation:
						</Strong>
						{" "}It provides enhanced face generation, allowing for the creation of lifelike and aesthetically pleasing faces in images.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Versatility in Image Modification:
							<br />
							- Inpainting:
						</Strong>
						{" "}Edit within the image to make specific changes or corrections.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							- Outpainting:
						</Strong>
						{" "}Extend the image beyond its original boundaries.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							- Image-to-image:
						</Strong>
						{" "}Use an existing image as a prompt to generate a new image.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							- Time Efficiency:
						</Strong>
						{" "}The ability to generate high-quality images quickly can save marketers and content creators significant time, especially when producing visual content in bulk.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							- Customised Solutions:
						</Strong>
						{" "}For those looking to integrate the tool into their platforms, customised solutions are available, ensuring seamless integration and usage.
					</Text>
				</Box>
				<Box
					md-order="-1"
					width="30%"
					display="flex"
					lg-width="50%"
					md-width="100%"
				>
					<Image
						object-fit="cover"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						margin="-1px -1px -1px 0px"
						src="https://uploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/256891_Create%20an%20illustration%20of%20a%20man%20in%20a%20neutral%20setti_xl-1024-v1-0.png?v=2023-10-04T14:42:17.970Z"
						srcSet="https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/256891_Create%20an%20illustration%20of%20a%20man%20in%20a%20neutral%20setti_xl-1024-v1-0.png?v=2023-10-04T14%3A42%3A17.970Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/256891_Create%20an%20illustration%20of%20a%20man%20in%20a%20neutral%20setti_xl-1024-v1-0.png?v=2023-10-04T14%3A42%3A17.970Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/256891_Create%20an%20illustration%20of%20a%20man%20in%20a%20neutral%20setti_xl-1024-v1-0.png?v=2023-10-04T14%3A42%3A17.970Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/256891_Create%20an%20illustration%20of%20a%20man%20in%20a%20neutral%20setti_xl-1024-v1-0.png?v=2023-10-04T14%3A42%3A17.970Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/256891_Create%20an%20illustration%20of%20a%20man%20in%20a%20neutral%20setti_xl-1024-v1-0.png?v=2023-10-04T14%3A42%3A17.970Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/256891_Create%20an%20illustration%20of%20a%20man%20in%20a%20neutral%20setti_xl-1024-v1-0.png?v=2023-10-04T14%3A42%3A17.970Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/256891_Create%20an%20illustration%20of%20a%20man%20in%20a%20neutral%20setti_xl-1024-v1-0.png?v=2023-10-04T14%3A42%3A17.970Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section md-padding="60px 0 60px 0" padding="84px 0 60px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				border-radius="10px"
				flex-direction="column"
				width="33.333%"
				md-padding="0px 0px 0px 0px"
				sm-padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="flex-start"
				margin="0px 0px 0px 0px"
				lg-margin="0px 0px 0px 0px"
				md-width="100%"
				sm-width="100%"
				padding="16px 16px 16px 16px"
				lg-width="50%"
			>
				<Text color="--primary" lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif" margin="0px 0px 0px 0px" font="--lead">
					Categories
				</Text>
				<Text
					lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					position="relative"
					z-index="1"
					color="--indigo"
					font="--headline4"
					margin="15px 0px 0px 0px"
					sm-font="700 25px/1.3 &quot;Inter&quot;, sans-serif"
				>
					AI tools for your needs
				</Text>
				<Text margin="0px 0px 0px 0px">
					Further tools are coming soon
				</Text>
				<Image height="48px" src="https://uploads.quarkly.io/6123949e957019001e284458/images/akar-icons_arrow-right.svg?v=2021-09-22T00:57:24.639Z" width="48px" />
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="16px 16px 16px 16px"
				width="33.333%"
				md-width="100%"
				sm-width="100%"
				lg-width="50%"
			>
				<LinkBox
					border-color="rgba(66, 82, 207, 0.1)"
					lg-height="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					height="100%"
					width="100%"
					flex-direction="column"
					border-style="solid"
					border-width="2px"
					border-radius="10px"
					padding="32px 32px 32px 32px"
					display="flex"
					justify-content="flex-end"
					md-min-height="200px"
					lg-min-height="350px"
					align-items="flex-start"
				>
					<Icon
						font="48px sans-serif"
						category="fa"
						icon={FaChartArea}
						size="48px"
						color="--indigo"
					/>
					<Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
						AI Tools for Financial Analysts and Investors
					</Text>
					<Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
						Unlock advanced financial forecasting, risk assessment, and market trend analysis with AI-driven tools, shaping the future of investment and financial planning
					</Text>
				</LinkBox>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="16px 16px 16px 16px"
				width="33.333%"
				md-width="100%"
				sm-width="100%"
				lg-width="50%"
			>
				<LinkBox
					border-color="rgba(66, 82, 207, 0.1)"
					lg-height="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					height="100%"
					width="100%"
					flex-direction="column"
					border-style="solid"
					border-width="2px"
					border-radius="10px"
					padding="32px 32px 32px 32px"
					display="flex"
					justify-content="flex-end"
					md-min-height="200px"
					lg-min-height="350px"
					align-items="flex-start"
				>
					<Icon
						font="48px sans-serif"
						category="fa"
						icon={FaRegFileCode}
						size="48px"
						color="--indigo"
					/>
					<Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
						AI Tools for Developers
					</Text>
					<Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
						Innovative AI solutions designed to streamline coding, enhance software development processes, and foster next-gen application creation.
					</Text>
				</LinkBox>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="16px 16px 16px 16px"
				width="33.333%"
				md-width="100%"
				sm-width="100%"
				lg-width="50%"
			>
				<LinkBox
					border-color="rgba(66, 82, 207, 0.1)"
					lg-height="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					height="100%"
					width="100%"
					flex-direction="column"
					border-style="solid"
					border-width="2px"
					border-radius="10px"
					padding="32px 32px 32px 32px"
					display="flex"
					justify-content="flex-end"
					md-min-height="200px"
					lg-min-height="350px"
					align-items="flex-start"
				>
					<Icon
						font="48px sans-serif"
						category="fa"
						icon={FaSchool}
						size="48px"
						color="--indigo"
					/>
					<Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
						AI Tools for Educators and Students
					</Text>
					<Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
						Harness the power of AI to revolutionise teaching methodologies, simplify learning, and create interactive educational experiences.
					</Text>
				</LinkBox>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="16px 16px 16px 16px"
				width="33.333%"
				md-width="100%"
				sm-width="100%"
				lg-width="50%"
			>
				<LinkBox
					border-color="rgba(66, 82, 207, 0.1)"
					lg-height="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					height="100%"
					width="100%"
					flex-direction="column"
					border-style="solid"
					border-width="2px"
					border-radius="10px"
					padding="32px 32px 32px 32px"
					display="flex"
					justify-content="flex-end"
					md-min-height="200px"
					lg-min-height="350px"
					align-items="flex-start"
				>
					<Icon
						font="48px sans-serif"
						category="fa"
						icon={FaClinicMedical}
						size="48px"
						color="--indigo"
					/>
					<Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
						AI Tools for Healthcare Professionals
					</Text>
					<Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
						Leverage AI-driven diagnostics, patient management, and research tools to elevate patient care and medical advancements.
					</Text>
				</LinkBox>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="16px 16px 16px 16px"
				width="33.333%"
				md-width="100%"
				sm-width="100%"
				lg-width="50%"
			>
				<LinkBox
					border-color="rgba(66, 82, 207, 0.1)"
					lg-height="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					height="100%"
					width="100%"
					flex-direction="column"
					border-style="solid"
					border-width="2px"
					border-radius="10px"
					padding="32px 32px 32px 32px"
					display="flex"
					justify-content="flex-end"
					md-min-height="200px"
					lg-min-height="350px"
					align-items="flex-start"
				>
					<Icon
						font="48px sans-serif"
						category="fa"
						icon={FaShoppingBasket}
						size="48px"
						color="--indigo"
					/>
					<Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
						AI Tools for E-commerce and Retail
					</Text>
					<Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
						Optimise online stores, predict consumer trends, and enhance customer experiences with AI-driven insights and automation.
					</Text>
				</LinkBox>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" lg-padding="60px 0 100px 0" padding="60px 0 160px 0">
			<Override slot="SectionContent" justify-content="center" align-items="center" />
			<Box
				width="87%"
				min-height="16px"
				height="30px"
				border-radius="30px 30px 0px 0px"
				background="--color-light"
				opacity="0.3"
			/>
			<Box
				background="--color-light"
				opacity="0.4"
				sm-display="none"
				width="93%"
				min-height="16px"
				height="30px"
				border-radius="30px 30px 0px 0px"
			/>
			<Box
				padding="66px 30px 66px 30px"
				flex-direction="column"
				sm-padding="30px 15px 30px 15px"
				sm-border-radius="12px"
				align-items="center"
				background="--color-light"
				border-radius="30px"
				justify-content="center"
				width="100%"
				display="flex"
				margin="0px 0px 0px 0px"
			>
				<Text font="--lead" color="--primary" text-transform="uppercase" margin="0px 0px 25px 0px">
					NEWSLETTER
				</Text>
				<Text
					text-align="center"
					lg-font="normal 600 30px/1.3 --fontFamily-googleInter"
					md-font="normal 600 25px/1.3 --fontFamily-googleInter"
					sm-font="normal 600 19px/1.3 --fontFamily-googleInter"
					margin="0px 0px 35px 0px"
					font="normal 600 39px/1.3 --fontFamily-googleInter"
					color="--dark"
				>
					Stay ahead with AI insights – subscribe now!
				</Text>
				<Box
					border-color="rgba(5, 0, 11, 0.1)"
					border-radius="16px"
					border-width="1px"
					border-style="solid"
					sm-width="100%"
					background="#ffffff"
					padding="12px 12px 12px 12px"
				>
					<Components.QuarklycommunityKitNetlifyForm>
						<Input
							placeholder="Email Address"
							sm-width="100%"
							sm-padding="12px 14px 12px 14px"
							border-width="0px"
							padding="14px 16px 14px 16px"
							width="320px"
							md-width="250px"
							name="Email Address"
							type="email"
							required
							background="rgba(255, 255, 255, 0)"
						/>
						<Button
							sm-width="100%"
							border-width="1px"
							border-color="--color-primary"
							font="--base"
							padding="14px 53px 14px 53px"
							sm-padding="12px 15px 12px 15px"
							hover-background="--color-light"
							hover-color="--primary"
							border-style="solid"
							background="--color-primary"
							border-radius="10px"
							md-padding="14px 30px 14px 30px"
						>
							Subscribe
						</Button>
					</Components.QuarklycommunityKitNetlifyForm>
				</Box>
			</Box>
		</Section>
		<Section background="--color-indigo" padding="100px 0 100px 0" md-padding="40px 0 40px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				flex-direction="column"
				md-justify-content="center"
				display="flex"
				padding="16px 16px 16px 16px"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				width="25%"
				md-align-items="center"
			>
				<Image
					src="https://uploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo_naked.png?v=2023-10-04T13:53:43.993Z"
					margin="0px 0px 24px 0px"
					max-width="171px"
					srcSet="https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo_naked.png?v=2023-10-04T13%3A53%3A43.993Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo_naked.png?v=2023-10-04T13%3A53%3A43.993Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo_naked.png?v=2023-10-04T13%3A53%3A43.993Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo_naked.png?v=2023-10-04T13%3A53%3A43.993Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo_naked.png?v=2023-10-04T13%3A53%3A43.993Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo_naked.png?v=2023-10-04T13%3A53%3A43.993Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo_naked.png?v=2023-10-04T13%3A53%3A43.993Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="25%"
				display="flex"
				md-width="100%"
				md-justify-content="center"
				empty-border-width="1px"
				empty-border-style="solid"
				flex-direction="column"
				padding="16px 16px 16px 16px"
				md-align-items="center"
			>
				<Text color="--white" font="--lead" margin="0px 0px 20px 0px">
					SHORT LINKS
				</Text>
				<Link
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="#"
					color="--white"
					opacity=".8"
				>
					Home
				</Link>
				<Link
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="#"
					color="--white"
				>
					Services
				</Link>
				<Link
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="#"
					color="--white"
				>
					Screenshots
				</Link>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 0px 0px"
					font="--base"
					href="#"
				>
					Contact
				</Link>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\na {\n  text-decoration: none;\n}"}
			</style>
			<script
				async={true}
				src={"https://www.googletagmanager.com/gtag/js?id=G-HZ33XRBXTY"}
				nomodule={true}
				place={"endOfHead"}
				rawKey={"651d7f3fd08b1e92cc52807d"}
			/>
			<script nomodule={true} place={"endOfHead"} rawKey={"651d7fc02f987da65d41b4b6"}>
				{"window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-HZ33XRBXTY');"}
			</script>
		</RawHtml>
	</Theme>;
});